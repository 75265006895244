.container {
    position: relative;
    margin: 2px;
}
.container > div {
    position: relative;
    margin: 5px;
}
.container > div:nth-of-type(1) .img {
    width: 70px;
    height: auto;
    box-shadow: 0 0 25px 1px #000;
    cursor: pointer;
}
@media (min-width: 600px){
    .container {
        margin: 5px;
    }
    .container > div:nth-of-type(1) .img {
        width: 100px;
    }
}
.scale:hover {
    scale: 1.05;
}
.counter {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    /*background-color: #000;*/
    /*border-radius: 10px;*/
    line-height: 18px;
    font-size: 16px;
    top: 5px;
    right: 5px;
    color: #fff;
    z-index: 3;
}
.strong {
    position: absolute;
    display: inline-block;
    width: 1.3vh !important;
    height: 1.3vh !important;
    background-color: #ece7e7;
    border-radius: 1.15vh;
    line-height: 1.3vh !important;
    font-size: 1vh !important;
    top: 0.2vh;
    left: 0.2vh;
    z-index: 3;
}
.strongLess {
    color: #f30808;
}
.strongMore {
    color: darkgreen;
}
.infoContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}
.infoContainer > div {
    position: relative;
    top: 10%;
    left: calc(50% - 15vh);
    width: 30vh;
    z-index: 4;
}
.infoContainer img {
    width: 30vh;
    box-shadow: 0 0 25px 1px #000;
}
.info {
    position: absolute;
    display: inline-block;
    width: 3vh;
    height: 3vh;
    background-color: #000;
    border-radius: 1.5vh;
    line-height: 3vh;
    font-size: 2vh;
    bottom: 1vh;
    right: 0.5vh;
    color: #fff;
    z-index: 3;
    cursor: pointer;
}
.info:hover {
    background-color: #fff;
    color: #000;
}
.infoClose {
    position: absolute;
    display: inline-block;
    top: 0;
    right: -30px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 20px;
    line-height: 24px;
    font-size: 30px;
    color: #000;
    z-index: 3;
    cursor: pointer;
}
.infoClose:hover {
    background-color: rgba(255, 255, 255, 0.19);
    color: #fff;
}
.description {
    color: #fff;
}
.infoContainer .strong {
    width: 6.4vh !important;
    height: 6.4vh !important;
    border-radius: 3.2vh;
    line-height: 6vh !important;
    font-size: 5vh !important;
    top: .9vh;
    left: 1vh;
}

