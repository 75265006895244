.kitContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 20px;
    width: 100%;
    user-select: none;
}
.kitField {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    border: 2px solid #fff;
    overflow-y: scroll;
    padding: 5px;
    width: 80vw;
}
@media (min-width: 600px){
    .kitContainer {
        flex-direction: row;
        align-items: normal;
        justify-content: flex-start;
        margin-top: 25px;
    }
    .kitField {
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 58vh;
        padding: 10px;
        width: 140px;
    }
}
.kitField:nth-child(1) {
    order: 1;
    margin-bottom: 10px;
}
.kitField:nth-child(3) {
    order: 2;
    margin-bottom: 10px;
}
@media (min-width: 600px){
    .kitField:nth-child(1) {
        margin-right: 15px;
    }
    .kitField:nth-child(3) {
        margin-left: 15px;
    }
}
@media (min-width: 800px){
    .kitField {
        width: 260px;
    }
}
@media (min-width: 1060px){
    .kitField {
        width: 380px;
    }
}
@media (min-width: 1400px){
    .kitField {
        width: 500px;
    }
}
.summary {
    display: flex;
    justify-content: flex-start;
    width: 200px;
    flex-direction: column;
    order: 3;
}
.summary {
    display: flex;
    justify-content: flex-start;
    width: 200px;
    flex-direction: column;
    order: 3;
}
.summary .leader > div:nth-of-type(1),
.summary .leader > div:nth-of-type(1) > div {
    margin: 0;
}
@media (min-width: 600px){
    .summary {
        order: 2;
    }
}
.leader {
    position: relative;
    display: flex;
    justify-content: center;
    width: 200px;
    flex-direction: row;
}
.leaderNav {
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    width: 30px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    background-color: #ffe05d;
    color: #924c22;
    border-radius: 20px;
    cursor: pointer;
}
.leaderNav:nth-of-type(2) {
    left: auto;
    right: 0;
}
.leaderNav:hover {
    color: #fff;
    background-color: rgba(255, 224, 93, 0.7);
    box-shadow: 0 0 5px 1px rgb(61, 47, 41);
}
.navDisabled {
    display: none;
}
.summaryNumbers {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.summaryNumbers label {
    margin-top: 5px;
    color: #fff;
}
.summaryNumbers span {
    color: #ffe05d;
}
.submitBtn {
    display: inline-block;
    color: #924c22;
    background-color: #ffe05d;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 10px;
    cursor:pointer;
}
.submitBtn:hover {
    color: #fff;
    background-color: rgba(255, 224, 93, 0.7);
    box-shadow: 0 0 5px 1px rgb(61, 47, 41);
}
.nameContainer {
    width: 300px;
    margin: 0 auto;
}
.nameContainer input{
    width: 100%;
}
.nameContainer input{
    width: 80%;
    margin-bottom: 0;
}
@media (min-width: 800px){
    .nameContainer input{
        width: 100%;
    }
}
.nameContainer input ~ div{
    color: white;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}
.error {
    color: #fff;
}

