.wrapper {
    margin-top: 20px;
}
.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    max-height: 58vh;
    border: 2px solid #fff;
    overflow-y: scroll;
    margin: 0 auto;
    padding: 10px;
}
.submitBtn {
    display: inline-block;
    color: #924c22;
    background-color: #ffe05d;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 20px;
    cursor:pointer;
}
.submitBtn:hover {
    color: #fff;
    background-color: rgba(255, 224, 93, 0.7);
    box-shadow: 0 0 5px 1px rgb(61, 47, 41);
}
.cardContainer.changedDisabled > div:nth-of-type(1) img {
    cursor: not-allowed;
}
.error {
    color: #fff;
}
.title {
    font-size: 14px;
}
.actionBtn {
    display: inline-block;
}