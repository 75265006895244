.app {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  text-align: center;
}
.link {
  position: relative;
  display: inline-block;
  width: 130px;
  font-weight: 600;
  padding: 10px 0;
  margin: 50px 10px 0 10px;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffe05d;
  background-color: #924c22;
  letter-spacing: 5px;
  z-index: 2;
}
.link:hover {
  color: #924c22;
  background-color: #ffe05d;
}
.img {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}
@media (min-width: 1000px) {
  .img {
    margin-top: -60px;
  }
}
@media (min-width: 1500px) {
  .img {
    margin-top: -90px;
  }
}
