.gameTable {
    width: 70vw;
    border-collapse: collapse;
    border-spacing: 0;
}
.gameTable thead th {
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
    padding: 10px;
}
.gameTable tbody td {
    font-weight: 300;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
    padding: 10px;
}
.gameTable tbody td:nth-of-type(2),
.gameTable tbody td:nth-of-type(3),
.gameTable tbody td:nth-of-type(5),
.gameTable tbody td:nth-of-type(6){
    border-right: none;
}
.gameTable tbody td:nth-of-type(3),
.gameTable tbody td:nth-of-type(4),
.gameTable tbody td:nth-of-type(6),
.gameTable tbody td:nth-of-type(7) {
    border-left: none;
}
.gameTable tbody td:nth-of-type(3),
.gameTable tbody td:nth-of-type(6) {
    width: 2vh;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
.gameTable tbody td:first-of-type,
.gameTable tbody td:last-of-type {
    text-align: center
}
.deckColumn {
    width: 4vh;
}
.deck {
    width: 100%;
}
.play {
    display: inline-block;
    background-color: var(--color-light);
    color: var(--color-darkner);
    text-decoration: none;
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}
@media (min-width: 420px){
    .play {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        font-size: 18px;
        line-height: 18px;
    }
}
@media (max-width: 800px){
    .gameTable {
        font-size: 14px;
        width: 95vw;
    }
    .gameTable tbody td:nth-of-type(3),
    .gameTable tbody td:nth-of-type(4),
    .gameTable tbody td:nth-of-type(6),
    .gameTable tbody td:nth-of-type(7) {
        visibility: hidden;
        width: 0;
        padding: 0;
        margin: 0;
    }
    .gameTable tbody td:nth-of-type(3) img,
    .gameTable tbody td:nth-of-type(4) img,
    .gameTable tbody td:nth-of-type(6) img,
    .gameTable tbody td:nth-of-type(7) img{
        visibility: hidden;
        width: 0;
        padding: 0;
        margin: 0;
    }
}
@media (min-width: 800px){
    .play {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        font-size: 28px;
        line-height: 27px;
    }
}
@media (min-width: 900px){
    .play:nth-of-type(1) {
        margin-right: 10px;
    }
}
.play:hover {
    background-color: #fff;
}
.live{
    position: relative;
    top: 3px;
    width: 2vh;
    margin-right: 5px;
}
