:root {
  --min-width: 420px;
  --color-light: #ffe05d;
  --color-darkner: #765440;
  --font-xs-size: 12px;
  --font-sm-size: 14px;
  --font-h1-sm-size: 24px;
  --font-h1-xs-size: 18px;
  --background-main: var(--color-darkner);
  --background-top-nav: var(--color-light);

}

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-background {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--background-main);
  text-align: center;
  overflow-x: scroll;
}
@media (min-width: 105vh){
  .container-background {
    overflow: hidden;
  }
}