.container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #765440;
}
.board {
    display: flex;
    position: relative;
    justify-content: flex-start;
    height: 100%;
    z-index: 1;
}
.board > div{
    position: relative;
    /*overflow-x: scroll;*/
}
@media (min-width: 600px){
    .board {
        justify-content: center;
    }
    .container {
        overflow: hidden;
        overflow-x: scroll;
    }
    .board > div{
        /*overflow: hidden;*/
    }
}
.link {
    position: absolute;
    display: inline-block;
    background-color: #34241c;
    width: 25px;
    height: 25px;
    top: 0px;
    right: 0px;
    color: #765440;
    text-align: center;
    line-height: 16px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    box-sizing: border-box;
    border: 4px solid #211912;
    box-shadow: 0 0 66px 1px #000;
}
.link:hover {
    color: #fff;
}
@media (min-width: 105vh){
    .link {
        width: 30px;
        height: 30px;
        top: 1px;
        right: -30px;
        line-height: 20px;
        font-size: 20px;
    }
}
.img {
    display: block;
    width: auto;
    height: 100%;
    box-shadow: 0 0 66px 1px #000;
}
.card > div:nth-of-type(1){
    margin: 0;
}
.card > div:nth-of-type(1) img {
    width: auto !important;
    height: 12.4vh !important;
}
.activeCard {
    cursor: pointer !important;
}
.card img:not(.activeCard){
    cursor: default !important;
}
.board .card > div:nth-of-type(1) span {
    width: 2vh;
    height: 2vh;
    line-height: 1.7vh;
    font-size: 2vh;
    right: 0.3vh;
    bottom: 0.7vh;
    cursor: pointer !important;
}
.cardOwn > div > div, .cardEnemy > div > div {
    margin-top: 0;
    margin-bottom: 0;
}
.board .card.cardOwn > div:nth-of-type(1) {
    transform-origin: bottom;
}
.board .card.cardEnemy > div:nth-of-type(1) {
    transform-origin: top;
}
.board .card:not(.deckOwn):not(.deckEnemy) > div:nth-of-type(1):hover {
    transform: scale(2);
    z-index: 10;
}
.card .activeCard.waiting {
    box-shadow: 0 0 8px 4px #fff !important;
}
.leaderOwn, .leaderEnemy {
    position: absolute;
    left: 2.5vh;
}
.leaderOwn {
    bottom: 17.5vh;
}
.leaderOut {
    cursor: not-allowed !important;
}
.leaderEnemy {
    top: 4.5vh;
}
.activeLeader {
    box-shadow: 0 0 5px 2px #fff !important;
    animation-name: shadow;
    /*animation-duration: 1s;*/
    /*animation-iteration-count: infinite;*/
}

/*@keyframes shadow {*/
/*    0% {*/
/*        opacity: 0.8;*/
/*    }*/
/*    25% {*/
/*        opacity: 0.9;*/
/*    }*/

/*    50% {*/
/*        opacity: 1;*/
/*    }*/

/*    75% {*/
/*        opacity: 0.9;*/
/*    }*/

/*    100% {*/
/*        opacity: 0.8;*/
/*    }*/
/*}*/

.deckOwn, .deckEnemy,
.dropOwn, .dropEnemy {
    position: absolute;
    left: 85.5vh;
}
.deckOwn img, .deckEnemy img,
.dropOwn img, .dropEnemy img {
    height: 12vh;
}
.dropOwn img, .dropEnemy img {
    opacity: 0.3;
    filter: saturate(35%);
}
.deckOwn {
    bottom: 37.8vh;
}
.deckEnemy {
    top: 25vh;
}
.dropOwn {
    bottom: 17vh;
}
.dropEnemy {
    top: 4vh;
}
.kit {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(100vh - 14vh);
    bottom: 1vh;
    left: 5px;
    /*right: 5px;*/
    width: calc(7.3vh * 13);
}
.pointOwn,.pointEnemy {
    position: absolute;
    width: auto;
    height: 3vh;
}
.pointEnemy {
    top: 19.5vh;
}
.pointOwn {
    bottom: 32.8vh;
}
.pointOwn:nth-of-type(1), .pointEnemy:nth-of-type(1) {
    left: 3.5vh;
}
.pointOwn:nth-of-type(2), .pointEnemy:nth-of-type(2) {
    left: 7.2vh;
}
.deckOwnIcon, .deckEnemyIcon {
    position: absolute;
    display: flex;
    width: auto;
    height: 4vh;
    left: 6vh;
    color: #d5a551;
    font-size: 2.5vh;
    /*font-weight: bold;*/
}
.deckEnemyIcon img, .deckOwnIcon img {
    width: auto;
    height: 100%;
    margin-left: 1vh;
}
.deckEnemyIcon {
    top: 25.5vh;
}
.deckOwnIcon {
    bottom: 38.5vh;
}
.countEnemy, .countOwn {
    position: absolute;
    width: 5vh;
    height: 5vh;
    border: 1px solid #72513c;
    border-radius: 3vh;
    background-color: #322117;
    left: 11.5vh;
    color: #d5a551;
    font-size: 2.3vh;
    text-align: center;
    line-height: 4.9vh;
}
.countEnemy {
    top: 13vh;
}
.countOwn {
    bottom: 26.5vh;
}

.countLineOwnSword, .countLineOwnRogue,
.countLineOwnBallista, .countLineEnemySword,
.countLineEnemyRogue, .countLineEnemyBallista {
    position: absolute;
    width: 4.5vh;
    height: 4.5vh;
    border: 1px solid #72513c;
    border-radius: 2.3vh;
    background-color: #322117;
    left: 18.1vh;
    color: #d5a551;
    font-size: 2.2vh;
    text-align: center;
    line-height: 4.3vh;
}
.countLineEnemyBallista {
    top: 6vh;
}
.countLineEnemyRogue {
    top: 20vh;
}
.countLineEnemySword {
    top: 34vh;
}
.countLineOwnSword {
    bottom: 47.5vh;
}
.countLineOwnRogue {
    bottom: 33.5vh;
}
.countLineOwnBallista {
    bottom: 19.5vh;
}
.skipBtn {
    position: absolute;
    bottom: 18vh;
    left: 11.3vh;
    width: 5vh;
    height: 5vh;
    border: 1px solid #72513c;
    background-color: #d5a551;
    color: #fff;
    font-size: 4vh;
    text-align: center;
    line-height: 4.3vh;
    cursor: pointer;
    transform: rotateY(180deg);
    box-shadow: inset rgba(255,255,255,.5) 1px 1px;
}
.skipBtn:hover {
    background-color: rgba(213, 165, 81, 0.60);
    color: #fff;
}
.skipFlag1, .skipFlag2 {
    position: absolute;
    left: 12vh;
    color: #fff;
    font-size: 4vh;
    line-height: 4.3vh;
    transform: rotateY(180deg);
    cursor: default;
}
.skipFlag1 {
    bottom: 18vh;
}
.skipFlag2 {
    top: 4vh;
}
.ownSword, .ownRogue, .ownBallista,
.enemySword, .enemyRogue, .enemyBallista {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 30.8vh;
    /*right: 13.5vh;*/
    width: calc(7.4vh * 7);
}
.board .enemySword .card > div:nth-of-type(1),
.board .enemyRogue .card > div:nth-of-type(1),
.board .enemyBallista .card > div:nth-of-type(1) {
    transform-origin: top;
}
.ownSword {
    top: calc(100vh - 56vh);
    bottom: 43vh;
}
.ownRogue {
    top: calc(100vh - 42vh);
    bottom: 29vh;
}
.ownBallista {
    top: calc(100vh - 28vh);
    bottom: 15vh;
}
.enemySword {
    top: calc(100vh - 70.3vh);
    bottom: 57.5vh;
}
.enemyRogue {
    top: calc(100vh - 84.3vh);
    bottom: 71.7vh;
}
.enemyBallista {
    top: calc(100vh - 98.5vh);
    bottom: 85.5vh;
}
.activeLine {
    box-shadow: 0 0 5px 1px #fff;
    cursor: pointer;
}
.activeOwnIndicator,
.activeEnemyIndicator {
    position: absolute;
    left: 3vh;
}
.activeOwnIndicator {
    bottom: 15vh;
}
.activeEnemyIndicator {
    top: 1vh;
}
.gameEndContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 5;
}
.gameEndContainer > div {
    position: relative;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    width: 200px;
    background-color: darkred;
    padding: 20px 20px 60px 20px;
    color: #ffe05d;
    z-index: 5;
}
.gameEndContainer > div p {
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
}
.gameEndContainer p:after {
    position: absolute;
    bottom: -60px;
    left: calc(50% - 20px);
    font-size: 40px;
}
.gameEndContainer .win:after {
    content: '\1F92A';
}
.gameEndContainer .lose:after {
    content: '\1F972';
}
.gameEndContainer .draw:after {
    content: '\1F917';
}
.elements {
    position: absolute;
    top: calc(100vh - 66vh);
    bottom: 47vh;
    left: 3.5vh;
    /*right: 80vh;*/
    width: calc(7.3vh * 1.7);
}
.elements .card:nth-of-type(2){
    position: absolute;
    right: 0;
    top: .5vh;
    z-index: 0;
}
.elements .card:nth-of-type(1){
    position: absolute;
    right: 3vh;
    top: 3.5vh;
    z-index: 1;
}
.elements .card:nth-of-type(3){
    position: absolute;
    right: 6vh;
    top: 6.5vh;
    z-index: 2;
}
.elements .card:nth-of-type(1):hover,
.elements .card:nth-of-type(2):hover{
    z-index: 3;
}
.cardElement.card > div > div{
    margin: 0;
}
.rain{
    background-color: rgba(42, 42, 114, 0.3);
}
.frost{
    background-color: rgba(126, 150, 238, 0.4);
}
.fog{
    background-color: rgba(241, 236, 236, 0.25);
}
.ownSwordBugle, .ownRogueBugle, .ownBallistaBugle,
.enemySwordBugle, .enemyRogueBugle, .enemyBallistaBugle {
    position: absolute;
    left: 23vh;
    right: 66vh;
}
.ownSwordBugle .card > div > div, .ownRogueBugle .card > div > div,
.ownBallistaBugle .card > div > div, .enemySwordBugle .card > div > div,
.enemyRogueBugle .card > div > div, .enemyBallistaBugle .card > div > div {
    margin: 0;
}
.board .enemySwordBugle .card > div:nth-of-type(1),
.board .enemyRogueBugle .card > div:nth-of-type(1),
.board .enemyBallistaBugle .card > div:nth-of-type(1) {
    transform-origin: top;
}
.ownSwordBugle {
    top: calc(100vh - 56vh);
    bottom: 43vh;
}
.ownRogueBugle {
    top: calc(100vh - 42vh);
    bottom: 29vh;
}
.ownBallistaBugle {
    top: calc(100vh - 28vh);
    bottom: 15vh;
}
.enemySwordBugle {
    top: calc(100vh - 70.3vh);
    bottom: 57.5vh;
}
.enemyRogueBugle {
    top: calc(100vh - 84.3vh);
    bottom: 71.7vh;
}
.enemyBallistaBugle {
    top: calc(100vh - 98.5vh);
    bottom: 85.5vh;
}
.canReplace {

}
.activeReplaceCard > div:nth-of-type(1) img {
    box-shadow: 0 0 5px 1px #fff !important;
    cursor: pointer !important;
}
.out {
    display:none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 5;
}
.out > div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
    z-index: 5;
}
.out > div > div > div:nth-of-type(1) img {
    width: auto !important;
    height: 24.8vh !important;
}
.outClose {
    position: absolute;
    display: inline-block;
    bottom: 20vh;
    right: calc(50vw - 15px);
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 20px;
    line-height: 24px;
    font-size: 30px;
    color: #000;
    z-index: 3;
    cursor: pointer;
}
.outClose:hover {
    background-color: rgba(255, 255, 255, 0.19);
    color: #fff;
}
@media (max-width: 105vh){
    .deckOwn, .deckEnemy,
    .dropEnemy, .dropOwn {
        left: 83.5vh;
    }
    .deckOwn {
        bottom: 36.5vh;
    }
    .deckEnemy {
        top: 24vh;
    }
    .dropEnemy {
        top: 3.6vh;
    }
    .dropOwn {
        bottom: 16vh;
    }
    .ownSword, .ownRogue,
    .ownBallista, .enemySword,
    .enemyRogue, .enemyBallista {
        left: 30vh;
        width: calc(7.18vh * 7);
    }
    .ownSword {
        top: calc(100vh - 57vh);
        bottom: 42.1vh;
    }
    .ownRogue {
        top: calc(100vh - 43vh);
        bottom: 28.4vh;
    }
    .ownBallista {
        top: calc(100vh - 29vh);
        bottom: 14.7vh;
    }
    .enemySword {
        top: calc(100vh - 71vh);
        bottom: 56vh;
    }
    .enemyRogue {
        top: calc(100vh - 84.6vh);
        bottom: 69.8vh;
    }
    .enemyBallista {
        top: calc(100vh - 98.1vh);
        bottom: 83.5vh;
    }
    .deckEnemyIcon {
        top: 24.5vh;
    }
    .deckOwnIcon {
        bottom: 37.5vh;
    }
    .pointEnemy {
        top: 18.8vh;
    }
    .pointOwn {
        bottom: 32vh;
    }
    .pointOwn:nth-of-type(1), .pointEnemy:nth-of-type(1) {
        left: 3vh;
    }
    .pointOwn:nth-of-type(2), .pointEnemy:nth-of-type(2) {
        left: 6.8vh;
    }
    .countLineOwnSword, .countLineOwnRogue,
    .countLineOwnBallista, .countLineEnemySword,
    .countLineEnemyRogue, .countLineEnemyBallista {
        left: 17.6vh;
    }
    .countLineEnemyBallista {
        top: 5vh;
    }
    .countLineEnemyRogue {
        top: 19vh;
    }
    .countLineEnemySword {
        top: 33vh;
    }
    .countLineOwnSword {
        bottom: 46.5vh;
    }
    .countLineOwnRogue {
        bottom: 32.5vh;
    }
    .countLineOwnBallista {
        bottom: 18.5vh;
    }
    .countEnemy {
        top: 12vh;
    }
    .countOwn {
        bottom: 25.5vh;
    }

    .ownSwordBugle, .ownRogueBugle, .ownBallistaBugle,
    .enemySwordBugle, .enemyRogueBugle, .enemyBallistaBugle {
        left: 22.5vh;
        width: calc(6.9vh * 1);
    }
    .ownSwordBugle {
        top: calc(100vh - 57vh);
        bottom: 42.1vh;
    }
    .ownRogueBugle {
        top: calc(100vh - 42vh);
        bottom: 28.5vh;
    }
    .ownBallistaBugle {
        top: calc(100vh - 29vh);
        bottom: 15vh;
    }
    .enemySwordBugle {
        top: calc(100vh - 71vh);
        bottom: 56.5vh;
    }
    .enemyRogueBugle {
        top: calc(100vh - 84.6vh);
        bottom: 69.8vh;
    }
    .enemyBallistaBugle {
        top: calc(100vh - 98.1vh);
        bottom: 83.5vh;
    }
    .elements {
        top: calc(100vh - 67.5vh);
        bottom: 45.8vh;
        left: 3.2vh;
    }
    .kit {
        top: calc(100vh - 15.2vh);
        width: calc(7.1vh * 13);
    }
}
