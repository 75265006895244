.container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
.container a {
    display: inline-block;
    width: 90px;
    color: var(--background-main);
    background-color: var(--background-top-nav);
    font-size: var(--font-xs-size);
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 5px;
    margin: 0 5px;
    cursor:pointer;
}
@media (min-width: 420px) {
    .container a {
        width: 90px;
        font-size: var(--font-sm-size);
        padding: 8px 15px;
        margin: 0 10px;
    }
}
.container a:hover {
    color: #fff;
    background-color: rgba(255, 224, 93, 0.7);
    box-shadow: 0 0 5px 1px rgb(61, 47, 41);
}