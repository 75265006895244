.hasError input {
    border-color: #AB3030FF;
}

.errorBlock {
    color: #AB3030FF;
    margin-bottom: 10px;
    padding-left: 5px;
    text-align: left;
    margin-top: -5px;
}

.hintBlock {
    color: #9a9494;
    margin-bottom: 5px;
    text-align: left;
}

input {
    display: block;
    padding: 5px 5px;
    margin:  0 auto 10px auto;
    border-radius: 2px;
    border-color: transparent;
}
@media (min-width: 420px){
    input {
        padding: 8px 10px;
        margin:  0 auto 10px auto;
        border-radius: 3px;
    }
}
input:focus,
input:focus-visible {
    outline: none;
}
input:focus {
    border: 1px solid #9a9494;
}
input:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
