.active, .notActive {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 8px;
}
.active {
    background-color: green;
}
.notActive {
    background-color: red;
}
@media (max-width: 105vh){
    .active, .notActive {
        width: 5px;
        height: 5px;
        border-radius: 3px;
        margin-right: 4px;
    }
}