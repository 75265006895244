.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.container {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    background-color: #ffe05d;
    padding: 35px 15px 20px;
    margin: 50px 10px 0 10px;
    width: 200px;
    z-index: 2;
    border-radius: 4px;
    text-align: center;
}
.closeBtn {
    position: absolute;
    background-color: #924c22;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    color: #ffe05d;
}
.closeBtn:before {
    position: absolute;
    content: '+';
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
    transform: rotate(45deg);
    line-height: 1;
}
.closeBtn:hover {
    color: #fff;
}
.submitBtn {
    display: inline-block;
    background-color: #924c22;
    width: 130px;
    cursor: pointer;
    border: none;
    color: #ffe05d;
    text-transform: uppercase;
    margin: 10px auto 0;
    padding: 10px;
    /*font-weight: 600;*/
    letter-spacing: 2px;
}
.submitBtn:not(:disabled):hover {
    color: #fff;
}
.submitBtn:disabled {
    opacity: 0.8;
    color: #ddd;
    cursor: not-allowed;
}