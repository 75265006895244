h1 {
    text-align: center;
    color: var(--background-top-nav);
    font-size: var(--font-h1-xs-size);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
}
@media (min-width: 400px) {
    h1 {
        font-size: var(--font-h1-sm-size);
        letter-spacing: 2px;
    }
}
.decksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    color: #fff;
    user-select: none;
}
.deck {
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
}
.deckImage {
    width: 80%;
    height: auto;
    box-shadow: 0 0 25px 1px #000;
    cursor: pointer;
}
@media (min-width: 420px) {
    .deckImage {
        width: 100%;
    }
}
.deckImage:hover {
    scale: 1.05;
}
.deckTitle {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #ffe05d;
    letter-spacing: 2px;
}
.deck:hover .deckTitle {
    color: #fff;
}
.deckTitle .actionBtn {
    text-align: center;
    padding: 10px 5px;
}
.deckTitle .actionBtn a {
    display: inline-block;
    background-color: #ffe05d;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    color: #765440;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    margin-right: 10px;
    line-height: 27px;
}

.deckTitle .actionBtn a span {
    margin-left: 4px;
}
.deckTitle .actionBtn a:hover {
    background-color: #fff;
}
.deck .deckTitle:hover {
    color: #ffe05d;
}
